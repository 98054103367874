import Page from "../Page";
import Text from "../Text100";
import TextSub from "../TextSub";
import Wrap from "../Wrap";

const Section2 = ({ isMobile }) => {
  return (
    <Wrap $height={isMobile ? "70vh" : ""}>
      <Page className="page2" data-aos="fade-up">
        <Text
          $fontSize={isMobile ? "50px" : "100px"}
          $marginBottom={isMobile ? "24px" : "80px"}
        >
          About <span style={{ color: "red" }}>Kimchi</span>
        </Text>
        <TextSub>
          Kimchi, a fermented napa cabbage dish, embodies Korean culture and
          identity beyond its culinary significance.
          <br />
          <br />
          In late Autumn, communities unite to make and share Kimchi, ensuring
          enough for the harsh winter and reminding people of the importance of
          living in harmony with nature.
          <br />
          This tradition is known as KimJang Day, the Kimchi Fest Day.
          <br />
          <br />
          $KIMCHI is born to spread and celebrate this rich cultural tradition
          together, extending its embrace beyond Korea to welcome and include
          others worldwide.
          <br />
          <br />
          By hodling onto $KIMCHI, we stand united and support each other
          through challenging times, mirroring the resilience found in Kimchi
          culture.
        </TextSub>
      </Page>
    </Wrap>
  );
};

export default Section2;
