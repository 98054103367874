import Page from "../Page";
import Text from "../Text";
import Wrap from "../Wrap";
import ImgBox from "../ImgBox";
import styled, { keyframes } from "styled-components";

const vibrateAnimation = keyframes`
    0%, 100% { transform: translate(0); }
    2.86% { transform: translate(-2px, 2px); }
    5.71% { transform: translate(-2px, -2px); }
    8.57% { transform: translate(2px, 2px); }
    11.43% { transform: translate(2px, -2px); }
    14.29% { transform: translate(0); }
`;

const VibrateAnimationDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: ${({ isMobile }) => (isMobile ? "80%" : "62.5%")};
  top: ${({ isMobile }) => (isMobile ? "74%" : "70%")};
  align-items: center;
  animation: ${vibrateAnimation} 3.5s infinite;
`;

const Section1 = ({ isMobile, degenMode, toggleDegenMode, modeContent }) => {
  return (
    <Wrap $height={isMobile ? "70vh" : ""}>
      <Page data-aos="fade-up">
        <Text $fontSize={isMobile ? "40px" : "80px"}>Spice Up Ur Wallet</Text>
        <Text
          $fontSize={isMobile ? "50px" : "100px"}
          $marginBottom={isMobile ? "50px" : ""}
          $color="red"
        >
          <a
            href="#!"
            className="App-link"
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => alert("Soon")}
          >
            With
          </a>{" "}
          $KIMCHI
        </Text>
        {modeContent.component}
        <button onClick={toggleDegenMode} style={{ cursor: "pointer" }}>
          <Text
            $fontSize={isMobile ? "40px" : "80px"}
            $lineHeight={"0.8"}
            $color={modeContent.textColor}
          >
            {modeContent.text}
          </Text>
        </button>
        {degenMode === false && (
          <VibrateAnimationDiv isMobile={isMobile}>
            <ImgBox
              src={"https://cdn.kimchi.network/kimchi/tail_arrow.svg"}
              width={isMobile ? "41.83px" : "110.81px"}
              height={isMobile ? "29.57px" : "78.33px"}
              position={"absolute"}
              top={"50%"}
              right={"100%"}
            />
            <Text $fontSize={isMobile ? "40px" : "80px"} $color={"red"}>
              Click
            </Text>
          </VibrateAnimationDiv>
        )}
      </Page>
    </Wrap>
  );
};

export default Section1;
