import styled from "styled-components";
import { media } from "../styles/theme";

const TextSub = styled.div`
  font-family: "PoorStory-Regular";
  font-size: 30px;
  font-weight: 400;
  color: ${(props) => props.$color || "black"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};
  line-height: ${(props) => props.$lineHeight || "1"};
  width: 80%;

  ${media.tablet} {
    font-size: 24px;
    width: unset;
  }

  ${media.mobile} {
    font-size: 18px;
    width: unset;
  }
`;

export default TextSub;
