import styled from "styled-components";

const ImgText = styled.div`
  position: absolute;
  font-size: ${(props) => props.$fontSize || "calc(10px + 2vmin)"};
  text-align: center;
  line-height: ${(props) => props.$lineHeight || "1"};
  top: ${(props) => props.$top || "0%"};
  left: ${(props) => props.$left || "0%"};
  width: ${(props) => props.$width || "0%"};
  transform: translate(-50%, 50%);
`;

export default ImgText;
