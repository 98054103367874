import React, { useState, useEffect } from "react";
import Page from "../Page";
import Wrap from "../Wrap";
import ImgBox from "../ImgBox";
import Text from "../Text";
import ImgBtn from "../ImgBtn";
import Magnifier from "../Magnifier";

import styled, { keyframes } from "styled-components";

const vibrateAnimation = keyframes`
    0%, 100% { transform: translate(0); }
    2.86% { transform: translate(-2px, 2px); }
    5.71% { transform: translate(-2px, -2px); }
    8.57% { transform: translate(2px, 2px); }
    11.43% { transform: translate(2px, -2px); }
    14.29% { transform: translate(0); }
`;

const VibrateAnimationDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: ${({ isMobile }) => (isMobile ? "80%" : "62.5%")};
  top: ${({ isMobile }) => (isMobile ? "74%" : "70%")};
  align-items: center;
  animation: ${vibrateAnimation} 3.5s infinite;
`;

const CustomCursor = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  width: 137px; // 커서의 크기를 조정하세요
  height: 247px; // 커서의 크기를 조정하세요
  background-image: url("https://cdn.kimchi.network/cursors/magnifier_cursor.svg");
  background-size: cover;
  transform: translate(-50%, -50%);
`;

const Section7 = ({ isMobile }) => {
  const [cursorChanged, setCursorChanged] = useState(false);
  const [cursorPos, setCursorPos] = useState({ x: -100, y: -100 });

  useEffect(() => {
    const updateCursorPos = (e) => {
      setCursorPos({ x: e.clientX, y: e.clientY });
    };

    if (cursorChanged) {
      window.addEventListener("mousemove", updateCursorPos);
      document.body.style.cursor = "none";
    } else {
      window.removeEventListener("mousemove", updateCursorPos);
      document.body.style.cursor = "";
    }

    return () => {
      window.removeEventListener("mousemove", updateCursorPos);
      document.body.style.cursor = "";
    };
  }, [cursorChanged]);

  const handleClick = () => {
    console.log("Button clicked!");
    setCursorChanged(!cursorChanged);
    console.log(`Cursor changed: ${!cursorChanged}`);
  };

  return (
    <>
      {/* {cursorChanged && <GlobalCursorStyle />} */}
      {isMobile
        ? ""
        : cursorChanged && (
            <CustomCursor
              style={{
                left: `${cursorPos.x}px`,
                top: `${cursorPos.y}px`,
                cursor: "none",
              }}
            />
          )}
      <Wrap $height={isMobile ? "78vh" : ""}>
        <Page data-aos="fade-up">
          <div
            style={{
              position: isMobile ? "static" : "relative",
              top: isMobile ? "10%" : "3%",
              right: isMobile ? "" : "",
              bottom: isMobile ? "3%" : "",
            }}
          >
            <Text
              $fontSize={isMobile ? "50px" : "150px"}
              $lineHeight={isMobile ? "18px" : "36px"}
              $marginBottom={isMobile ? "25px" : "50px"}
              $color="red"
            >
              U’ll Never Die
            </Text>
            <Text
              $fontSize={isMobile ? "40px" : "100px"}
              $lineHeight={isMobile ? "40px" : "80px"}
              $marginBottom={isMobile ? "42.5px" : "25px"}
            >
              But Reborn
            </Text>
          </div>
          <Magnifier
            imageUrl={"https://cdn.kimchi.network/kimchi/kimchi_never_die.svg"}
            isMobile={isMobile}
            cursorChanged={cursorChanged}
          />
          <div style={{ display: isMobile ? "none" : "block" }}>
            <ImgBtn
              src={"https://cdn.kimchi.network/kimchi/magnifier_1.svg"}
              width={isMobile ? "41.83px" : "136.36px"}
              height={isMobile ? "29.57px" : "95.38px"}
              position={"absolute"}
              bottom={"7%"}
              right={"40%"}
              onClick={handleClick}
              style={{
                zIndex: 100,
                cursor: cursorChanged ? "none" : "pointer",
              }}
            />
            <VibrateAnimationDiv isMobile={isMobile}>
              <ImgBox
                src={"https://cdn.kimchi.network/kimchi/tail_arrow_1.svg"}
                width={isMobile ? "41.83px" : "110.81px"}
                height={isMobile ? "29.57px" : "78.33px"}
                position={"absolute"}
                top={"50%"}
                right={"95%"}
              />
              <Text $fontSize={isMobile ? "40px" : "80px"}>?!?</Text>
            </VibrateAnimationDiv>
          </div>
        </Page>
      </Wrap>
    </>
  );
};

export default Section7;
