import styled from "styled-components";
import { media } from "../styles/theme";

const Page = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: 1920px;
  max-height: 1080px;
  height: ${(props) => (props.height ? props.height : "100%")};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "EastSeaDokdo-Regular"};
  font-weight: 400;
  background: ${(props) => (props.background ? props.background : "white")};
  display: flex;
  flex-direction: ${(props) =>
    props.$flexDirection ? props.$flexDirection : "column"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : "calc(10px + 2vmin)"};
  color: ${(props) => (props.color ? props.color : "black")};
  box-sizing: border-box;
  margin: auto;
  ${media.tablet} {
    padding: 0 20px;
  }
`;

export default Page;
