import Page from "../Page";
import Wrap from "../Wrap";
import ImgBox from "../ImgBox";
import Text from "../Text";

const Section8 = ({ isMobile }) => {
  return (
    <Wrap $height={isMobile ? "70vh" : ""}>
      <Page data-aos="fade-up">
        <Text
          $fontSize={isMobile ? "50px" : "150px"}
          $color="red"
          $lineHeight={isMobile ? "18px" : "36px"}
          $marginTop={isMobile ? "20px" : "76px"}
          $marginBottom={isMobile ? "30px" : "66px"}
        >
          Reborn
        </Text>
        <Text
          $fontSize={isMobile ? "40px" : "80px"}
          $lineHeight={isMobile ? "40px" : "80px"}
          $marginBottom={isMobile ? "74.85px" : "100px"}
        >
          As Kimchi Warrior
        </Text>
        <ImgBox
          src={"https://cdn.kimchi.network/kimchi/kimchi_warrior.svg"}
          width={isMobile ? "276.4px" : "591px"}
          height={isMobile ? "278.8px" : "597px"}
          bottom={isMobile ? "" : "5%"}
        />
      </Page>
    </Wrap>
  );
};

export default Section8;
