import Page from "../Page";
import Wrap from "../Wrap";
import ImgBox from "../ImgBox";
import IcnBox from "../IcnBox";
import Text from "../Text";
import ImgText from "../ImgText";

const Section10 = ({ handleClick, isMobile }) => {
  return (
    <Wrap $height={isMobile ? "50vh" : ""}>
      <Page data-aos="fade-up">
        <ImgBox
          src={"https://cdn.kimchi.network/kimchi/kimchi_dish.svg"}
          width={isMobile ? "302.4px" : "681px"}
          height={isMobile ? "134.8px" : "262px"}
          bottom={isMobile ? "20px" : "40px"}
        >
          <ImgText
            $fontSize={isMobile ? "25px" : "50px"}
            $lineHeight={isMobile ? "20px" : "50px"}
            $top={isMobile ? "30%" : "25%"}
            $left={isMobile ? "51%" : "51%"}
            $width={isMobile ? "80%" : "80%"}
          >
            0x1892d2669CF625Ff3334
            <br />
            8b1FDed25ADeb0D1Fb1E
          </ImgText>
        </ImgBox>
        <Text
          $fontSize={isMobile ? "40px" : "100px"}
          $lineHeight={isMobile ? "40px" : "80px"}
          $marginBottom={isMobile ? "65px" : "130px"}
        >
          You’ve been served
        </Text>
        <div>
          <IcnBox
            src={"https://cdn.kimchi.network/icons/etherscan.svg"}
            width={isMobile ? "32.28px" : "46.57px"}
            height={isMobile ? "32.28px" : "46.57px"}
            margin={isMobile ? "0 15.6px 0 15.6px" : "0 31.2px 0 0"}
            onClick={() => handleClick("etherscan")}
          />
          <IcnBox
            src={"https://cdn.kimchi.network/icons/X.com.svg"}
            width={isMobile ? "32.28px" : "46.57px"}
            height={isMobile ? "32.28px" : "46.57px"}
            margin={isMobile ? "0 15.6px 0 0" : "0 31.2px 0 0"}
            onClick={() => handleClick("X.com")}
          />
          <IcnBox
            src={"https://cdn.kimchi.network/icons/Telegram.svg"}
            width={isMobile ? "32.28px" : "46.57px"}
            height={isMobile ? "32.28px" : "46.57px"}
            margin={isMobile ? "0 15.6px 0 0" : "0 31.2px 0 0"}
            onClick={() => handleClick("Telegram")}
          />
          <IcnBox
            src={"https://cdn.kimchi.network/icons/Dexscreener.svg"}
            width={isMobile ? "32.28px" : "46.57px"}
            height={isMobile ? "32.28px" : "46.57px"}
            margin={isMobile ? "0 15.6px 0 0" : "0 31.2px 0 0"}
            onClick={() => handleClick("Dexscreener")}
          />
          <IcnBox
            src={"https://cdn.kimchi.network/icons/Dextools.svg"}
            width={isMobile ? "32.28px" : "46.57px"}
            height={isMobile ? "32.28px" : "46.57px"}
            margin={isMobile ? "0 15.6px 0 0" : "0 31.2px 0 0"}
            onClick={() => handleClick("Dextools")}
          />
        </div>
      </Page>
    </Wrap>
  );
};

export default Section10;
