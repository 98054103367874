import styled from "styled-components";

const IcnBox = styled.button`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  background-image: url(${(props) => props.src});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: ${(props) => props.margin};
  ${(props) => props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
  `}
`;

export default IcnBox;
