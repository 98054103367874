import styled from "styled-components";

const ImgBtn = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  max-height: 100vh;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: ${(props) => props.position || "relative"};
  top: ${(props) => props.top || "auto"};
  left: ${(props) => props.left || "auto"};
  right: ${(props) => props.right || "auto"};
  bottom: ${(props) => props.bottom || "auto"};
  transform: ${(props) => props.transform || "none"};
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
`;

export default ImgBtn;
