import styled from "styled-components";
import { media } from "../styles/theme";

const Text100 = styled.div`
  font-family: ${(props) =>
    props.$fontFamily ? props.$fontFamily : "EastSeaDokdo-Regular"};
  font-weight: 400;
  font-size: 100px;
  color: ${(props) => props.$color || "black"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};
  line-height: ${(props) => props.$lineHeight || "1"};

  ${media.tablet} {
    font-size: 60px;
  }
`;

export default Text100;
