import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import AOS from "aos";
import theme from "./styles/theme";
import "./styles/style.scss";
import "aos/dist/aos.css";
import Sections from "./components/Sections/Sections";

// const handleClick = () => {
//   console.log("Button clicked!");
// };

function App() {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty("--vw", `${vw}px`);
      if (getIsMobile()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    AOS.init({
      offset: 100,
    });
    setLoading(false);
    setVh();
    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  const getIsMobile = () => {
    return window.innerWidth < 1200;
  };

  

  return (
    <>
      <ThemeProvider theme={theme}>
        {!loading && <Sections isMobile={isMobile} />}
      </ThemeProvider>
    </>
  );
}

export default App;
