import styled from "styled-components";

const WrapS = styled.section`
  display: flex;
  align-items: center;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  justify-content: center;
  width: 100vw;
  height: ${(props) => props.$height || "100vh"};
  box-sizing: border-box;
  overflow: hidden;
`;

const Wrap = ({ children, isMobile, $height }) => {
  return (
    <WrapS className="section" isMobile={isMobile} $height={$height}>
      {children}
    </WrapS>
  );
};

export default Wrap;
