import Page from "../Page";
import Wrap from "../Wrap";
import Text from "../Text";

const Section9 = ({ isMobile }) => {
  return (
    <Wrap $height={isMobile ? "50vh" : ""}>
      <Page>
        <Text
          $fontSize={isMobile ? "50px" : "150px"}
          $color="red"
          $lineHeight={isMobile ? "18px" : "36px"}
          $marginBottom={isMobile ? "30px" : "100px"}
        >
          Kimchinomics
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Text
            $fontSize={isMobile ? "30px" : "60px"}
            $lineHeight={isMobile ? "30px" : "60px"}
            $marginLeft={isMobile ? "" : "80px"}
            $marginRight={isMobile ? "" : "80px"}
            $marginBottom={isMobile ? "20px" : "0"}
          >
            $KIMCHI Supply
            <br /> 1,000,000,000,000
          </Text>
          <Text
            $fontSize={isMobile ? "30px" : "60px"}
            $lineHeight={isMobile ? "30px" : "60px"}
            $marginLeft={isMobile ? "" : "80px"}
            $textAlign={isMobile ? "" : "start"}
          >
            <div style={{ color: "red" }}>90% LP (Burned, Renounced)</div>10%
            Marketing, Airdrop
            <br /> 0% Dev
          </Text>
        </div>
      </Page>
    </Wrap>
  );
};

export default Section9;
