import Page from "../Page";
import Wrap from "../Wrap";
import ImgBox from "../ImgBox";
import Text from "../Text";

const Section6 = ({ isMobile }) => {
  return (
    <Wrap $height={isMobile ? "70vh" : ""}>
      <Page $flexDirection={isMobile ? "column-reverse" : "row"}>
        <ImgBox
          src={"https://cdn.kimchi.network/kimchi/kimchi_pain.svg"}
          width={isMobile ? "373.41px" : "760px"}
          height={isMobile ? "321.5px" : "667px"}
          left={isMobile ? "5%" : "0"}
          data-aos="fade-right"
        />
        <div data-aos="fade-left">
          <Text
            $fontSize={isMobile ? "50px" : "150px"}
            $lineHeight={isMobile ? "40px" : "80px"}
            $marginBottom={isMobile ? "14px" : "94px"}
            $color="red"
          >
            Accept Pain
          </Text>
          <Text
            $fontSize={isMobile ? "40px" : "100px"}
            $lineHeight={isMobile ? "40px" : "80px"}
          >
            No Pain, No Gain
          </Text>
        </div>
      </Page>
    </Wrap>
  );
};

export default Section6;
