import Page from "../Page";
import Wrap from "../Wrap";
import ImgBox from "../ImgBox";
import Text from "../Text";

const Section4 = ({ isMobile }) => {
  return (
    <Wrap $height={isMobile ? "100vh" : ""}>
      <Page data-aos="fade-up">
        <ImgBox
          src={"https://cdn.kimchi.network/kimchi/kimchi_scared.svg"}
          width={isMobile ? "534px" : "896px"}
          height={isMobile ? "534px" : "896px"}
          style={{
            left: isMobile ? "10%" : "",
            top: isMobile ? "" : "0%",
            right: isMobile ? "" : "5%",
            bottom: isMobile ? "10%" : "10%",
          }}
        />
        <div
          style={{
            position: "absolute",
            left: isMobile ? "45%" : "55%",
            top: isMobile ? "65%" : "60%",
            right: isMobile ? "" : "10%",
            bottom: isMobile ? "3%" : "15%",
            marginLeft: isMobile ? "30px" : "90px",
          }}
        >
          <Text
            $fontSize={isMobile ? "50px" : "150px"}
            $lineHeight={isMobile ? "40px" : "80px"}
            $marginBottom={isMobile ? "5px" : "50px"}
            $color="red"
          >
            Don't
            <br />
            Be Scared
          </Text>
          <Text
            $fontSize={isMobile ? "40px" : "100px"}
            $lineHeight={isMobile ? "40px" : "80px"}
          >
            Stay Strong
            <br />
            (2gether)
          </Text>
        </div>
      </Page>
    </Wrap>
  );
};

export default Section4;
