import React, { useState } from "react";
import "../styles/Magnifier.css";

import styled from "styled-components";

const ImgBox = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  max-height: 100vh;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: ${(props) =>
    props.position || "relative"}; // 기본값을 'relative'로 설정
  top: ${(props) => props.top || "auto"};
  left: ${(props) => props.left || "auto"};
  right: ${(props) => props.right || "auto"};
  bottom: ${(props) => props.bottom || "auto"};
  transform: ${(props) => props.transform || "none"};
  transition: ${(props) => props.transition || "none"};
`;

const Magnifier = ({ imageUrl, isMobile, cursorChanged }) => {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setPosition({ x, y });

    setCursorPosition({
      x: e.clientX - left,
      y: e.clientY - top,
    });
    const isWithinBottomQuarter = e.clientY - top > height * 0.85;

    setShowMagnifier(isWithinBottomQuarter);
  };

  return (
    <div
      className="img-magnifier-container"
      onMouseEnter={() => setShowMagnifier(true)}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setShowMagnifier(false)}
    >
      {isMobile ? (
        <ImgBox
          src={"https://cdn.kimchi.network/kimchi/kimchi_never_die.svg"}
          width={isMobile ? "245.5px" : "460px"}
          height={isMobile ? "332.8px" : "625px"}
          position={isMobile ? "static" : "relative"}
          top={isMobile ? "" : "4%"}
        />
      ) : (
        <div
          className="magnifier-img"
          style={{
            width: isMobile ? "245.5px" : "460px",
            height: isMobile ? "332.8px" : "625px",
            backgroundImage: `url(${imageUrl})`,
            backgroundRepeat: "no-repeat",
            position: isMobile ? "static" : "relative",
            top: isMobile ? "" : "6%",
            left: isMobile ? "" : "10.5%",
            justifyContent: "center",
          }}
        ></div>
      )}
      {isMobile
        ? ""
        : showMagnifier &&
          cursorChanged && (
            <div
              style={{
                position: "absolute",
                left: `${cursorPosition.x - 65}px`,
                top: `${cursorPosition.y - 120}px`,
                pointerEvents: "none",
              }}
            >
              <div
                className="magnifier-image"
                style={{
                  backgroundImage: `url(${imageUrl})`,
                  backgroundPosition: `${position.x + 5}% ${position.y - 8}%`,
                  backgroundSize: "2800% 2800%",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "white",
                }}
              ></div>
            </div>
          )}
    </div>
  );
};

export default Magnifier;
