import styled from "styled-components";

const Text = styled.div`
  font-family: ${(props) =>
    props.$fontFamily ? props.$fontFamily : "EastSeaDokdo-Regular"};
  font-weight: 400;
  font-size: ${(props) => props.$fontSize || "calc(10px + 2vmin)"};
  color: ${(props) => props.$color || "black"};
  margin-top: ${(props) => props.$marginTop || "0"};
  margin-left: ${(props) => props.$marginLeft || "0"};
  margin-bottom: ${(props) => props.$marginBottom || "0"};
  margin-right: ${(props) => props.$marginRight || "0"};
  line-height: ${(props) => props.$lineHeight || "1"};
  text-align: ${(props) => props.$textAlign || "center"};
`;

export default Text;
