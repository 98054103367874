import Page from "../Page";
import Wrap from "../Wrap";
import ImgBox from "../ImgBox";
import Text from "../Text";

const Section5 = ({ isMobile }) => {
  return (
    <Wrap $height={isMobile ? "50vh" : ""}>
      <Page $flexDirection={isMobile ? "column" : "row"} data-aos="fade-up">
        <div data-aos="fade-right">
          <Text
            $fontSize={isMobile ? "50px" : "150px"}
            $lineHeight={isMobile ? "40px" : "80px"}
            $marginBottom={isMobile ? "10px" : "67px"}
            $color="red"
          >
            {isMobile ? (
              "Don't Run Away"
            ) : (
              <>
                Don't Run
                <br />
                Away
              </>
            )}
          </Text>
          <Text
            $fontSize={isMobile ? "40px" : "100px"}
            $lineHeight={isMobile ? "40px" : "80px"}
          >
            Face the FUD
            <br />
          </Text>
        </div>
        <ImgBox
          data-aos="fade-left"
          src={"https://cdn.kimchi.network/kimchi/kimchi_dont_run.svg"}
          width={isMobile ? "368px" : "959px"}
          height={isMobile ? "322px" : "959px"}
          left={isMobile ? "20%" : ""}
        />
      </Page>
    </Wrap>
  );
};

export default Section5;
