import Page from "../Page";
import Wrap from "../Wrap";
import Text from "../Text";

const Section3 = ({ isMobile }) => {
  return (
    <Wrap $height={isMobile ? "30vh" : ""}>
      <Page data-aos="fade-up">
        <Text
          $fontSize={isMobile ? "50px" : "150px"}
          $marginBottom={isMobile ? "8px" : "18px"}
          $color="red"
        >
          Community Ethos
        </Text>
        <Text $fontSize={isMobile ? "40px" : "100px"}>
          When you’re with us ...
        </Text>
      </Page>
    </Wrap>
  );
};

export default Section3;
