const customMediaQuery = (maxWidth) => `@media (max-width: ${maxWidth}px)`;

export const media = {
  custom: customMediaQuery,
  pc: customMediaQuery(1440),
  tablet: customMediaQuery(1200),
  mobile: customMediaQuery(576),
};

const theme = {
  window: {
    pc: media.pc,
    tab: media.tablet,
    mobile: media.mobile,
    mobileS: media.mobile,
  },
};

export default theme;
