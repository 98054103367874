import React, { useState, useEffect } from "react";
import ImgBox from "../ImgBox";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Section10 from "./Section10";
import { useRive, Layout, Fit } from "@rive-app/react-canvas";
import AOS from "aos";
import { Howl } from "howler";

const KimchiAni = ({ isMobile }) => {
  const { rive, RiveComponent } = useRive({
    src: "images/riv/kimchi_dance.riv",
    stateMachines: "bumpy",
    layout: new Layout({ fit: Fit.FitHeight }),
    autoplay: true,
    animations: ["Timeline 1"],
  });

  return (
    <RiveComponent
      style={{
        width: isMobile ? "250px" : "500px",
        height: isMobile ? "266.5px" : "531px",
        alignItems: "center",
        position: "relative",
        bottom: isMobile ? "5%" : "",
      }}
      // onEnded={() => rive && rive.play("Timeline 1")}
      data-aos="zoom-in"
    />
  );
};

const handleClick = (src) => {
  switch (src) {
    case "etherscan":
      // alert("Soon");
      window.location.href =
        "https://basescan.org/token/0x1892d2669cf625ff33348b1fded25adeb0d1fb1e";
      break;
    case "X.com":
      window.location.href = "https://twitter.com/kimchi_fomo";
      break;
    case "Telegram":
      // alert("Soon");
      window.location.href = "https://t.me/kimchifever000";
      break;
    case "Dexscreener":
      // alert("Soon");
      window.location.href =
        "https://dexscreener.com/base/0x18fbb1f52949c089d94fb200035573b39d773837";
      break;
    case "Dextools":
      // alert("Soon");
      window.location.href =
        "https://www.dextools.io/app/en/base/pair-explorer/0x18fbb1f52949c089d94fb200035573b39d773837?t=1712589130913";
      break;
    default:
      alert("Soon");
  }
};

const Sections = ({ isMobile }) => {
  const [degenMode, setDegenMode] = useState(false);

  const onLeave = (origin, destination, direction) => {
    //   for AOS
    let target = origin.item.querySelectorAll(".aos-init");
    for (let i = 0; i < target.length; i++) {
      target[i].classList.remove("aos-animate");
    }
  };

  const toggleDegenMode = () => {
    setDegenMode(!degenMode);
  };

  const kimchiAniSound = new Howl({
    src: ["/sounds/kimchi_fever.mp3"],
    loop: degenMode,
    html5: true,
  });

  useEffect(() => {
    if (degenMode) {
      kimchiAniSound.play();
    } else {
      kimchiAniSound.stop();
    }

    return () => {
      kimchiAniSound.unload();
    };
  }, [degenMode]);

  const modeContent = degenMode
    ? {
        component: <KimchiAni isMobile={isMobile} playing={true}></KimchiAni>,
        text: (
          <>
            Degen Mode
            <br /> ON
          </>
        ),
        textColor: "red",
        backgroundColor: "transparent",
        border: "none",
      }
    : {
        component: (
          <>
            <ImgBox
              src={"https://cdn.kimchi.network/kimchi/kimchi_before_degen.svg"}
              style={{
                width: isMobile ? "221px" : "406px",
                height: isMobile ? "266.5px" : "531px",
                bottom: isMobile ? "4%" : "",
              }}
              data-aos="zoom-in"
            />
          </>
        ),
        text: (
          <>
            Turn On
            <br />
            Degen Mode
          </>
        ),
        textColor: "black",
        backgroundColor: "transparent",
        border: "none",
      };

  return (
    <>
      <Section1
        toggleDegenMode={toggleDegenMode}
        modeContent={modeContent}
        isMobile={isMobile}
        degenMode={degenMode}
      />
      <Section2 isMobile={isMobile} />
      <Section3 isMobile={isMobile} />
      <Section4 isMobile={isMobile} />
      <Section5 isMobile={isMobile} />
      <Section6 isMobile={isMobile} />
      <Section7 isMobile={isMobile} />
      <Section8 isMobile={isMobile} />
      <Section9 isMobile={isMobile} />
      <Section10 handleClick={handleClick} isMobile={isMobile} />
    </>
  );
};

export default Sections;
